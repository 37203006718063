export const enUOttawaLabPeerAssessment = {
  title: 'Peer Evaluation',
  rubric: {
    label: 'Values:',
    1: '1 = strongly agree',
    2: '2 = agree',
    3: '3 = disagree',
    4: '4 = strongly disagree',
  },
  header: {
    selfGrade: 'Yourself',
    criteria: 'Attribute',
  },
  partnerName: 'Name of Partner',
  partnerCountQuestion: 'How many partners did you have?',
  noPartner: 'No partner',
  onePartner: '1 partner',
  twoPartner: '2 partners',
  threePartner: '3 partners',
  fourPartner: '4 partners',
  fivePartner: '5 partners',
  instruction:
    'Write the names of your group members in the numbered boxes. Then assign yourself a value for each listed attribute. Finally do the same for each of your group members. Use a few words to explain your choice of values.',
  attributes: {
    1: 'Was dependable. Met when arranged, did their share of the work without complaint or excuse.',
    2: 'Willingly accepted assigned tasks.',
    3: 'Contributed positively to group discussions.',
    4: 'Finished work on time and ensured that it was complete.',
    5: 'Helped others with their work when/as needed.',
    6: 'Did work accurately and completely.',
    7: 'Contributed a fair share to the task.',
    8: 'Worked well with other group members.',
    9: 'Overall was a valuable member of the team.',
  },
  total: 'Column Totals',
};
